<script>
import heroImageSrc from '@/assets/images/provider/login-background.jpg'
import logoSrc from '@/assets/images/logo/toddl.co_logo white.svg'

export default {
  name: 'ClaimSuccess',
  data() {
    return {
      email: this.$route.query.email,
      heroImageSrc,
      logoSrc,
    }
  },
}
</script>

<template>
  <div class="auth-container">
    <div class="auth-hero">
      <img :src="heroImageSrc" class="auth-hero-image" />
    </div>
    <div class="auth-content">
      <div class="auth-logo">
        <img :src="logoSrc" class="logo" />
        <span class="logo-badge">
          <small>{{ $t('for') }}&nbsp;</small>
          <strong>{{ $t('business') }}</strong>
        </span>
      </div>
      <p>
        {{ $t('claim_success_message', { email }) }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.auth-container {
  display: grid;
  gap: 0;
  grid-template-columns: minmax(0, 0%) minmax(0, 1fr);
  height: 100vh;
}

.auth-hero {
  position: relative;
  height: 100%;
}

.auth-hero-image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.auth-content {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0e1a1a;
}

.auth-logo {
  display: flex;
  flex-direction: row;
  height: fit-content;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.logo {
  width: 100%;
}

.logo-badge {
  font-size: 26px;
  display: inline-block;
  color: #4b64a5;
  margin-bottom: 8px;
}

.logo-badge strong {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .auth-container {
    grid-template-columns: minmax(0, 66%) minmax(0, 1fr);
  }
}
</style>
